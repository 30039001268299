import React from 'react'
import Layout from "components/Layout"
import Seo from 'components/Seo'
import { OutboundLink } from "gatsby-plugin-google-analytics"

const MachineShedPage = () => {

    const PageData = [
        {
            title: "Cotton Picker",
            videoURL: "https://www.hutcheonandpearce.com.au/get-ready-for-cotton-pick"
        },
        {
            title: "Harvester",
            videoURL: "https://www.hutcheonandpearce.com.au/get-ready-for-harvest/"
        },
        {
            title: "Planter maintenance",
            videoURL: "https://www.hutcheonandpearce.com.au/get-ready-for-planting/"
        }
    ];

    return(
    <Layout>
      <Seo 
        title="Machine Shed" 
        description="Guides and howtos on your equipment"
      />
      <h1 className="mb-2 text-3xl font-bold text-center text-tecsight-blue">Machinery Shed</h1>
      <ul className="grid grid-cols-2 gap-2 p-4 font-bold text-center text-tecsight-blue">
        {PageData.map((item, index) => {
            return(
                <li key={index} className="flex items-center justify-center p-4 bg-yellow-100 rounded-md shadow-sm">
                    <OutboundLink href={item.videoURL} className="flex items-center justify-between p-4 justify">
                        <span className="font-bold">{item.title}</span>
                    </OutboundLink>
                </li>
            );
        })}
      </ul>
    </Layout>
    );
}

export default MachineShedPage;